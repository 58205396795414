 .bodyblog{
  font-family: "Archivo", sans-serif;
  text-align: center;
 
}
.container {
display: grid;
grid-template-columns: repeat(1fr, 1fr);
grid-gap: 50px;
overflow-wrap: break-word;
}


.containerblog{
  overflow-wrap: break-word;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  width: 80%;
  margin:auto;
  gap:20px;

}



.title{
margin:0px 0px 40px;
  color: #6ad3b1;
  font-size: 40px;
  text-shadow: 0 0 12px;
  padding-top: 40px;
}
.content{
  color: #6ad3b1;
  font-size: 16px;
  position: absolute;
  bottom: 10px;
  margin-top: 16px;
}

.top{
  position: absolute;
  width: 100%;
  margin-top: -7px;
  height: 18.6px;
  transition: all 0.7s linear;
  border-top: 0;
  margin-left: -1px;
}

#root > div > div.bodyblog > div > div > div > div{
  margin-bottom: 5% !important  ;
}

.row {
  width: 100%;
  height: 100%;
  background-color:#11171D;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  margin :0 auto;
  border: 1px  #6ad3b27a dashed;
  flex-wrap: wrap;
  position: relative;
}

.row:hover .top{ 
transition: all 0.4s linear;
background:#6ad3b221;
transform: scale(1, 20);
}

.row::before {
  position:absolute;
  content:'';
  width: 25%;
  height:216px;
  background:#6ad3b1;
  margin-top:0px;
  left:-3px;
  z-index: -1;
  transition: all 0.5s linear;
}

.row::after {
  position:absolute;
  content:'';
  width: 25%;
  height:216px;
  background: #6ad3b1;
  margin-top:0px;
  right:-3px;
  z-index: -1;
  transition: all 0.5s linear;
}

.row:hover.row::before,
.row:hover.row::after{
  width:100%;
  box-shadow: 0 0 15px 10px  #6ad3b1;
  transition: all 0.7s linear;
  background:#6ad3b230;
}

.seemore{
  text-decoration: none;
  background: transparent;
  display: block;
  border: 1px dashed rgb(106, 211, 117,0.31);
  transition: all 0.5 linear;
  color:#ffff;
  transition: all 0.5 linear;
  padding:10px;
  margin-left: 300px;
  font-size: 15px;
  
}

.seemore:hover{
background-color: #6ad3b1;;
}

.img {
  width: 200px;
  height: 200px;
}

.box-textt{
  color: white;
  padding-bottom: 20px;
  font-size: 40px;
}
.box-textd{
color: white;
padding-bottom: 20px;
font-size: 20px;
max-width: 300px;
}

@media only screen and (max-width: 768px) {
.containerbl {
  grid-template-columns: 1fr;
  grid-gap: 20px;
}

.containerblog{
  grid-template-columns: auto;
}

.title {
  font-size: 30px;
}
.content {
  font-size: 14px;
}
.seemore {
  margin-left: 0;
  font-size: 13px;
}

.img {
  width: 100px;
  height: 100px;
}
.box-textt {
  font-size: 30px;
}
.box-textd {
  font-size: 16px;
}

#root > div > div.bodyblog > div > div > div > div::before,#root > div > div.bodyblog > div > div > div > div::after{
height: inherit;
}



}