
.Highlights{
padding: 40px 0px;
border-bottom: 1px  #6ad3b27a dashed;
}

.containerHighlights11 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 368px 368px ;
  grid-gap: 40px;
  margin: 0 auto;
  width: 70%;
  justify-content: center;

}

.row {
  width: 100%;
  height: 100%;
  background-color:#11171D;
  display: flex;
  justify-content: center; 
  align-items: center;
  margin :0 auto;
  border: 1px  #6ad3b27a dashed;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 50%;
}

.box-img {
  
  width: 422.39px;
  height: 250px;
  margin :0 auto;
  padding-top: 0px;
  padding-bottom: 25px;
  z-index: 2;
}


.title{
font-size: 28px;
  margin:0px 0px 40px;
  color: #6ad3b1;
  text-shadow: 0 0 12px;
  text-align: center;
}
.below{
  text-align: center;
  font-size: 18px;
  color: #ffffff;

}
.content{
  font-size: 16px;
  color: #6ad3b1;
  position: absolute;
  bottom: 10px;
  margin-top: 16px;
}

.top{
  position: absolute;
  width: 100%;
  margin-top: -7px;
  height: 18.6px;
  transition: all 0.7s linear;
  border-top: 0;
  margin-left: -1px;
}

.row:hover .top{ 
transition: all 0.4s linear;
background:#6ad3b210;
transform: scale(1, 20);
}

.row::before {
  position:absolute;
  content:'';
  width: 25%;
  height:376px;
  background:#6ad3b1;
  margin-top:0px;
  left:-3px;
  z-index: -1;
  transition: all 0.5s linear;
}

.row::after {
  position:absolute;
  content:'';
  width: 25%;
  height:376px;
  background: #6ad3b1;
  margin-top:0px;
  right:-3px;
  z-index: -1;
  transition: all 0.5s linear;
}

.row:hover.row::before,
.row:hover.row::after{
  width:100%;
  box-shadow: 0 0 9px 3px  #6ad3b1;
  transition: all 0.7s linear;
  background:#6ad3b210;
}

.seemore1{
  text-align: center;
  text-decoration: none;
  display: block;
  position: absolute;
  right:15%;
  border: 1px  #6ad3b27a dashed;
  padding:10px;
  color:#ffff;
  /* margin-top: 0px; */
}

.seemore1::before{
content: "";
display: block;
position: absolute;
left: 0;
top: 0;
height: 100%;
width: 0%;
background: #6AD3B1;
transition: all 0.5s linear;
z-index: -1;
}
  
.seemore1:hover.seemore1::before{
width:100%;

}

.seemoreH{
  text-align: center;
  text-decoration: none;
  display: block;
 
  right:15%;
  border: 1px  #6ad3b27a dashed;
  padding:10px;
  color:#ffff;
}
.seemoreH::before{
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0%;
  background: #6AD3B1;
  transition: all 0.5s linear;
  z-index: -1;
}

.seemoreH:hover.seemoreH::before{
  width:100%;
}

@media (max-width: 767px) {
  .containerHighlights11 {
    grid-template-rows: 22rem 22rem 22rem 22rem;
   

  }

  .box-img {
    width:fit-content;
  
  }
  /* .containerHighlights11 {
    display: grid;
    justify-content: center;
grid-template-columns: 1fr 1fr;
grid-template-rows: 22rem 22rem 22rem 22rem ;
grid-gap: 30px;
margin:  auto;
width: 70%; 
  } */

  /* .box-img {
    width:unset;
  }
  #root > div > div:nth-child(2) > div > div > div:nth-child(1) > div{
    width: 70%;
  } */

}
