.notfound{
    color: #6ad3b1;
    line-height: 56px;
    margin-bottom: 1rem;
    text-shadow: 0 0 5px;
    font-size: 15rem;
    font-weight: 500;
    text-align: center;
}

.pagenotfound > p{
    color: #6ad3b1;
padding-top:50px
}


.pagenotfound{

display: flex;
flex-direction: column;
align-items: center;
margin: 200px auto;


}

@media only screen and (max-width: 600px) {

    .notfound{
        font-size: 10rem;
    }

    .pagenotfound{
        margin: 100px auto;
    }

}