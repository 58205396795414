.containerdash{
    display:flex;
}
.compflexbutton{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    gap:10px
}
.sidebar{
    background:#223950;
    color: #fff;
    height: 100vh;
    transition: all 0.5s;
}
.top_section{
    display: flex;
    align-items:center;
    padding:20px 15px;
}
.logo{
    font-size: 30px;
}
.bars{
    display: flex;
    font-size: 25px;
    margin-left: 50px;
}
.link{
    display: flex;
    color: #fff;
    padding: 10px 15px;
    gap: 15px;
    transition: all 0.5s;
}
.link:hover{
    background:lightskyblue;
    color: #000;
    transition: all 0.5s;
}
.active{
    background: lightskyblue;
    color: #000;
}
.icon, .link_text{
    font-size: 20px;
   }


    .link{
     
        text-decoration: none ;}
.logoutBTN {
    margin: 20px;
    padding: 1em;
    background-color: #6ad3b1;
    color: #11171d;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: all 0.5s ease;
  }


@media (max-width: 767px) {

.sidebar{
    height: fit-content;
    width: 100% !important;
}


    .compflex{
flex-direction: column;


    }}    