.footer {
  color: white;
  padding-top: 7px;
  padding-bottom: 10px;
  border-top: 1px dashed rgba(106, 211, 177, 0.31);
  text-align: center;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
}
