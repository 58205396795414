.header1 {
  display: grid;
  grid-template-columns: 0.2fr 1.5fr;
  border: 1px solid #1b2931;
  width: 100%;
}

.a ,.linka{
  width: 90%;
  margin: auto;
}
.imglogo {
width: 90%;
margin: auto;
}

@media (max-width: 767px) {
  .imglogo {
   width: 50%;
   padding:5px 5px 5px 0px;
 margin:unset;
 justify-content: start;
  }

  .header1 {
    grid-template-rows:2fr ;
    grid-template-columns: none;
 
  }
}