


    @media (max-width: 767px) {

        .container-home{

    grid-template-rows:22rem 22rem 22rem 22rem  22rem 22rem !important;
    margin-bottom: 20rem !important;
}
    }