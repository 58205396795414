.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;

  border-radius: 25px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.scroll-to-top button {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  color: #6ad3b1;
  font-size: 1.5rem;
}
