
  .all{
  display: block;
  /* height: auto; */
  padding: 2.5rem 0;
  border-bottom: 1px #6ad3b27a dashed;
  }

  .all-high{
    display: block;
    height:fit-content;
    padding: 2.5rem 0;
    border-bottom: 1px #6ad3b27a dashed;
    }
  .container-home {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 22rem 22rem 22rem;
  grid-gap: 3%;
  margin: 0 auto;
  width: 70%;
  margin-bottom: 5rem;
  }

  .container-home-cert {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 22rem;
    grid-gap: 3%;
    margin: 0 auto;
    width: 70%;
    margin-bottom: 5rem;
    }
    .container-home-high {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 22rem 22rem;
      grid-gap: 3%;
      margin: 0 auto;
      width: 70%;
      margin-bottom: 5rem;
      }
  .row-home {
  width: 100%;
  height: 100%;
  background-color:#11171D;
  display: flex;
  justify-content: center; 
  align-items: center;
  margin :0 auto;
  border: 1px #6ad3b27a dashed;
  flex-wrap: wrap;
  position: relative;
  }
  .box-img-home {
  width: 75%;
  height: 75%;
  margin :0 auto;
  padding-top: 0;

  z-index: 2;
  }
  .img-home{

  width: 100%;
  height: 85%;


  }
  .titleee{
  font-size: 1.75rem;
  margin:0 0 2.5rem;
  color: #6ad3b1;
  text-shadow: 0 0 0.75rem;
  text-align: center;
  }
  .content-home{
  font-size: 1rem;
  color: #6ad3b1;
  position: absolute;
  bottom: 0.6rem;
  margin-top: 1rem;
  text-align: center;
  }
  .top-home{
  position: absolute;
  width: 100%;
  margin-top: -0.4rem;
  height: 1.15rem;
  transition: all 0.7s linear;
  border-top: 0;
  margin-left: 0.06rem;
  }
  .row-home:hover .top-home{ 
  transition: all 0.4s linear;
  background:#6ad3b210;
  transform: scale(1, 20);
  }
  .row-home::before {
  position:absolute;
  content:'';
  width: 25%;
  height:22.5rem;
  background:#6ad3b1;
  margin-top:0;
  left:-0.19rem;
  z-index: -1;
  transition: all 0.5s linear;
  }
  .row-home::after {
  position:absolute;
  content:'';
  width: 25%;
  height:22.5rem;
  background: #6ad3b1;
  margin-top:0;
  right:-0.19rem;
  z-index: -1;
  transition: all 0.5s linear;
  }
  .row-home:hover.row-home::before,
  .row-home:hover.row-home::after{
  width:100%;
  box-shadow: 0 0 0.5rem 0.19rem #6ad3b1;
  transition: all 0.7s linear;
  background:#6ad3b210;
  }
  .btncert{
  text-align: center;
  text-decoration: none;
  display: block;
  position: absolute;
  right:15%;
  border: 1px #6ad3b27a dashed;
  padding:10px;
  color:#ffff;
  margin-top:25.6rem;
  }
  .btncert::before{
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0%;
  background: #6AD3B1;
  transition: all 0.5s linear;
  z-index: -1;
  }
  .btncert:hover.btncert::before{
  width:100%;
  }
  
  /**** acknowledjment*******/

  
  .all1{
  padding: 5rem 0;
  border-bottom: 1px #6ad3b27a dashed;
  }
  .container1 {
  margin: 0 auto;
  width: 70%; 
  }
  .textwidget-home{
  color: white;
  font-size: 0.9rem;
  
  text-align: center;
  }
  .boxImg1{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-content: space-around;
  margin-bottom: 0.6rem;
  margin-top: 0.13rem;
  }
  .boxImg2{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-content: space-around;
  }
  .imeg1{
  width: 10.3rem;
  height: 3.4rem;
  }
  .imeg2{
  width: 10.3rem;
  height:fit-content;
  }
  
  .shadow{
  position: absolute;
  width: 100%;
  margin-top: 2.2rem;
  height: 1.18rem;
  transition: all 0.7s linear;
  border-top: 0;
  margin-left: 0;
  }
  .row1:hover .shadow{ 
  transition: all 0.4s linear;
  background:#6ad3b210;
  transform: scale(1, 14);
  }
  .row1 ,.row2 {
  width: 100%;
  height: 15.8rem;
  background-color:#11171D;
  margin :0 auto;
  border: 1px #6ad3b27a dashed;
  padding: 1.25rem 0 0 0;
  position: relative;
  margin-bottom: 1.8rem;
  }

  .row1::before ,.row2::before {
  position:absolute;
  content:'';
  width: 20%;
  height:16.7rem;
  background:#6ad3b1;
  margin-top:-1.4rem;
  left:-0.13rem;
  z-index: -1;
  transition: all 0.5s linear;
  }
  .row1::after {
  position:absolute;
  content:'';
  width: 20%;
  height:16.7rem;
  background: #6ad3b1;
  margin-top:-13.4rem;
  right:-0.13rem;
  z-index: -1;
  transition: all 0.5s linear;
  }
  
  .row2::after {
  position:absolute;
  content:'';
  width: 20%;
  height:16.6rem;
  background: #6ad3b1;
  margin-top:-10rem;
  right:-0.13rem;
  z-index: -1;
  transition: all 0.5s linear;
  }
  .row1:hover.row1::before,
  .row1:hover.row1::after,
  .row2:hover.row2::before,
  .row2:hover.row2::after{
  width:100%;
  box-shadow: 0 0 9px 3px #6ad3b1;
  transition: all 0.7s linear;
  background:#6ad3b210;
  }
  
  .shadow1 {
  position: absolute;
  width: 100%;
  margin-top: 2.06rem;
  height: 1.18rem;
  transition: all 0.7s linear;
  border-top: 0;
  margin-left: 0;
  }
  
  .row2:hover .shadow1 {
  transition: all 0.4s linear;
  background: #6ad3b210;
  transform: scale(1, 14);
  }
  
  /******writeups********/
  .textwidgetWriteups {
  color: white;
  font-size: 1rem;
  text-align: center;
  }
  
  .ReadFullWriteup {
  position: absolute;
  text-decoration: none;
  color: white;
  font-size: 1rem;
  border: 1px #6ad3b27a dashed;
  padding: 0.6rem;
  margin-top: 2.2rem;
  
  margin-bottom: 0.6rem;
  z-index: 1;
  transition: all 0.7s linear;
  margin-left: 36rem;
  }
  .gglimeg {
  width: 4rem;
  height: 4rem;
  margin-left: 38rem;
  }
  
  .ReadFullWriteup:hover {
  background-color: #6ad3b1;
  
  }
  .seemore-high{
  text-align: center;
  text-decoration: none;
  display: block;
  position: absolute;
  right: 17rem;
  border: 1px #6ad3b27a dashed;
  padding:0.6rem;
  color:#ffff;
  margin-top: 48rem;

  }
  .seemore-high::before{
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0%;
  background: #6AD3B1;
  transition: all 0.5s linear;
  z-index: -1;
  }
  .seemore-high:hover.seemore-high::before{
  width:100%;
  }

  .modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
  }
  
  
    .modal-content {
      width: auto;
      height: auto;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 9999;
    }
  

    @media (max-width: 767px) {

    #root > div > div:nth-child(2) > div:nth-child(3) > div > div.row1{
height: unset;

    }
    #root > div > div:nth-child(2) > div:nth-child(3) > div > div.row1::after{

      height: 13.5rem;
  }
  #root > div > div:nth-child(2) > div:nth-child(6) > div > div > div::after{
    height: 14rem;
  }
  #root > div > div:nth-child(2) > div:nth-child(2) > div {
   
    grid-template-columns:none;
    grid-template-rows: 22rem 22rem 22rem 22rem 22rem 22rem  ;
    margin-bottom: 20rem;
  }

  #root > div > div:nth-child(2) > div:nth-child(4) > div{
    grid-template-columns:none;
    grid-template-rows: 22rem 22rem ;
  

  }
  #root > div > div:nth-child(2) > div:nth-child(4) > div > a{
    margin-top: 47rem;
  }

  #root > div > div:nth-child(2) > div.all-high > div{

    grid-template-columns:none;
    grid-template-rows: 22rem 22rem 22rem 22rem  ;
    margin-bottom: 15rem;
  }

  #root > div > div:nth-child(2) > div.all-high > div > a{
    margin-top: 100rem;
    right: 15%;
  }
  #root > div > div:nth-child(2) > div > div{
    grid-template-columns:none;
    grid-template-rows: 22rem 22rem  ;
  }


  
}